<template>
  <transition name="fade" mode="out-in">
    <PropertyVisitJoinWaitlist
      v-if="
        !loadingHighlightedDates &&
          highlightedDates.dates.length === 0 &&
          !isLoggedIn
      "
    />
    <div v-else>
      <Calendar
        v-model="visitDate"
        :inline="true"
        :highlighted="highlightedDates"
        :loading="loadingHighlightedDates"
        @selected="getTimeSlots"
      />
      <div v-if="visitDate">
        <div ref="slots" class="p-5 lg:p-7.5 my-7.5 -mx-6 lg:mx-0 shadow-card">
          <span class="tg-mobile-body-small lg:tg-desktop-body text-gray-cc">
            {{ weekday }}
          </span>
          <h3 class="tg-mobile-header-3 lg:tg-desktop-header-3">
            {{ formattedDate }}
          </h3>
          <PropertyVisitCreateSlots
            v-if="isLoggedIn"
            :visit-date="visitDate"
            @updateTimeSlots="updateTimeSlots"
          />
          <transition name="fade" mode="out-in">
            <div v-if="loadingTimeSlots" class="text-center">
              <i class="far fa-spinner-third animate-spin" />
            </div>
            <template v-else-if="timeSlots.length">
              <PropertyVisitSeller
                v-if="isLoggedIn"
                :time-slots="timeSlots"
                @updateTimeSlots="updateTimeSlots"
              />
              <PropertyVisitBuyer v-else :time-slots="timeSlots" />
            </template>
            <div v-else-if="!isLoggedIn" key="noTimeSlotsAvailable">
              {{ $t('no_timeslots_available') }},
              {{ $t('please_select_circled_dates') }}.
            </div>
          </transition>
        </div>
        <!-- <button
          v-if="isLoggedIn"
          type="button"
          class="px-5 py-0.5 my-8 mx-auto block tg-mobile-button-small lg:tg-desktop-body text-white bg-brand-primary rounded-full shadow-card hover:bg-opacity-84"
        >
          Terug naar soldandset.be
        </button> -->
        <router-link
          v-if="!isLoggedIn"
          :to="{ name: 'PropertyVisitGetInTouch' }"
          class="inline-block w-full tg-mobile-link link text-center"
        >
          <i class="fal fa-bolt mr-2" />
          {{ $t('suitable_time_not_found') }}
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
import { formatDateForLocale } from '@/helpers';
import { compareAsc } from 'date-fns';
import {
  getPropertyAvailableDates,
  getPropertyAvailableSlots
} from '@/services/calendarService';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'PropertyVisitTimeSlots',
  components: {
    Calendar: () =>
      import(/* webpackChunkName: "Calendar" */ '@/components/Calendar'),
    PropertyVisitJoinWaitlist: () =>
      import(
        /* webpackChunkName: "PropertyVisitJoinWaitlist" */ '@/components/PropertyVisitJoinWaitlist'
      ),
    PropertyVisitBuyer: () =>
      import(
        /* webpackChunkName: "PropertyVisitBuyer" */ '@/components/PropertyVisitBuyer'
      ),
    PropertyVisitSeller: () =>
      import(
        /* webpackChunkName: "PropertyVisitSeller" */ '@/components/PropertyVisitSeller'
      ),
    PropertyVisitCreateSlots: () =>
      import(
        /* webpackChunkName: "PropertyVisitCreateSlots" */ '@/components/PropertyVisitCreateSlots'
      )
  },
  data() {
    return {
      propertyId: this.$route.params.propertyId,
      highlightedDates: {
        dates: []
      },
      loadingTimeSlots: false,
      timeSlots: [],
      loadingHighlightedDates: false
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('property', ['propertyVisitDate']),
    visitDate: {
      get() {
        return this.propertyVisitDate;
      },
      set(date) {
        this.SET_VISIT_DATE(date);
      }
    },
    formattedDate() {
      return this.visitDate ? formatDateForLocale(this.visitDate) : '';
    },
    weekday() {
      return this.visitDate
        ? this.visitDate.toLocaleDateString(this.$i18n.locale, {
            weekday: 'long'
          })
        : '';
    },
    available_only() {
      return this.isLoggedIn ? 0 : 1;
    }
  },
  watch: {
    visitDate(newVal) {
      if (newVal) {
        this.$nextTick(function() {
          if (this.$refs.slots) this.$refs.slots.scrollIntoView();
        });
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations('property', ['SET_VISIT_DATE']),
    init() {
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      this.visitDate = date; // Set the visit date to 00:00:00 of the current day to make sure validTime validation works right.

      this.getHighlightedDates();
      this.getTimeSlots(this.visitDate);
    },
    getHighlightedDates() {
      this.loadingHighlightedDates = true;
      this.highlightedDates.dates = [];

      const date = new Date();
      const startAt = date.toISOString();

      getPropertyAvailableDates(
        this.propertyId,
        this.available_only,
        startAt
      ).then(response => {
        response.data.forEach(dateObj => {
          this.highlightedDates.dates.push(new Date(dateObj.date));
        });
        this.loadingHighlightedDates = false;
      });
    },
    getTimeSlots(event) {
      this.loadingTimeSlots = true;

      const date =
        compareAsc(new Date(), new Date(event)) > 0
          ? new Date()
          : new Date(event);

      const startAt = date.toISOString();

      date.setHours(23);
      date.setMinutes(59);

      const endAt = date.toISOString();

      getPropertyAvailableSlots(
        this.propertyId,
        this.available_only,
        startAt,
        endAt
      )
        .then(response => {
          this.timeSlots = response.data.results;
        })
        .finally(() => {
          this.loadingTimeSlots = false;
        });
    },
    updateTimeSlots() {
      this.getTimeSlots(this.visitDate);
      this.getHighlightedDates();
    }
  }
};
</script>
